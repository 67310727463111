.pr-content-wrap {
  display: flex;
  position: absolute;
  top: 12em;
  /* max-width: 90%; */
  width: 90%;
  margin-left: 5%;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 450px;
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-color: rgb(251, 176, 59) rgb(251, 176, 59, 0);
  scrollbar-width: thin;
  scroll-behavior: smooth;
  opacity: 0;
  transition: 2s;
  transition-delay: 2s;
  z-index: 3;
}

.pr-content-wrap::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.pr-content-wrap::-webkit-scrollbar-thumb {
  background: rgb(251, 176, 59);
}
.pr-content-wrap::-webkit-scrollbar-track {
  background: rgb(251, 176, 59, 0);
}

.pr-content-menu {
  position: absolute;
  top: 12em;
  left: 780px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  display: none;
  opacity: 0;
  transition: 2s;
  transition-delay: 2s;
}

.pr-content-menu ul {
  list-style: none;
  margin: 0;
  padding: 0.5em;
}
.pr-content-menu ul li {
  padding-bottom: 1em;
  font-weight: 700;
}

/* RESPONSIVE MENU  */

@media screen and (min-width: 62em) {
  .pr-content-menu {
    display: block;
  }
}
@media screen and (max-width: 62em) {
  .pr-content-menu {
    display: none;
  }
}
@media screen and (min-width: 90em) {
  .pr-content-menu {
    display: none;
  }
}

/* RESPONSIVE WRAPPER & CONTENT*/

/* ---Width */
@media screen and (max-width: 90em) {
  .pr-content-wrap {
    overflow-y: scroll;
    max-width: 610px;
    min-width: 610px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  #grid2 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 52em) {
  .pr-content-wrap {
    max-width: 90%;
    min-width: 90%;
    height: auto;
    bottom: 4em;
  }
}

@media screen and (max-width: 36em) {
  .pr-content-wrap {
    bottom: 4em;
    height: auto;
  }
}

/* ---Height */
@media screen and (max-height: 37em) {
  .pr-content-wrap {
    top: 4em;
    max-height: 80%;
  }
}
