@font-face {
  font-family: 'qhyts';
  src: url('./assets/fonts/Qhytsdakx.woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(230, 230, 230);
  background-image: url(./assets/img/bg2b.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
}

a {
  color: rgb(85, 85, 85);
  text-decoration: none;
}

a:hover {
  /* color: rgb(179, 179, 179); */
  text-decoration: underline;
  transition: 0.5s;
}
