button:focus,
button:active {
  border: none;
  outline: none;
}

.logo-top {
  position: absolute;
  width: 8em;
  top: 1.5em;
  left: 1.5em;
}
.bottom-title {
  position: absolute;
  left: 5%;
  width: 1000px;
  height: 200px;
  max-width: 90%;
  max-height: 20%;
  overflow: hidden;
  bottom: -50%;
  transition: 1s;
  transition-delay: 1s;
  z-index: 1;
  /* border: 1px solid blue; */
}

.bottom-title-img {
  position: absolute;
  display: block;
  width: auto;
  max-width: 100%;
  height: 100%;
  /* left: 1%;
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 20%;
  overflow: hidden; */
  /* border: 1px solid red; */
  object-fit: contain;
  object-position: left bottom;
}
.grid-container {
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 2em;
  min-width: 600px;
  max-width: 600px;
  min-height: 450px;
  display: grid;
  grid-template-columns: 16px 32px auto 32px 16px;
  grid-template-rows: 16px 20px 16px auto 16px;
  gap: 0px 0px;
  grid-template-areas:
    'par par . . .'
    'par2 . title . .'
    'par2 . . par4 par4'
    'par2 content content content par5'
    'par2 par3 . par6 par5';
  background-color: rgb(204, 204, 204);
  z-index: 3;
}
.grid-content {
  grid-area: content;
  padding-left: 0.4em;
  padding-right: 0.4em;
  font-size: 1em;
}
.grid-title {
  grid-area: title;
  font-family: qhyts;
  font-size: 2.5em;
  padding-left: 1em;
  color: rgb(85, 85, 85);
}
.grid-title span {
  position: relative;
  bottom: 0.5em;
}
.grid-par {
  grid-area: par;
  background-color: rgb(179, 179, 179);
}
.grid-par2 {
  grid-area: par2;
  background-color: rgb(179, 179, 179);
}
.grid-par3 {
  grid-area: par3;
  background-color: rgb(179, 179, 179);
}
.grid-par4 {
  grid-area: par4;
  background-color: rgb(179, 179, 179);
}
.grid-par5 {
  grid-area: par5;
  background-color: rgb(179, 179, 179);
}
.grid-par6 {
  grid-area: par6;
  background-color: rgb(179, 179, 179);
}

@media screen and (max-height: 37em) {
  .logo-top {
    width: 4em;
    top: 0.2em;
    left: 0.2em;
  }
}
@media screen and (max-width: 52em) {
  .grid-container {
    max-width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 36em) {
  .grid-title {
    font-size: 1.8em;
  }
}
