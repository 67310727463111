#nav {
  background-color: rgb(153, 153, 153);
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  overflow: hidden;
  transition: 0.5s;
  position: fixed;
  left: 100%;
  top: 0;
  z-index: 4;
}

.nav-list {
  list-style: none;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10%;
}

.nav-item {
  font-size: 8em;
  font-family: 'qhyts';
  color: rgb(251, 176, 59);
  letter-spacing: 0.1em;
  /* border: 1px solid red; */
  font-weight: 700;
  width: fit-content;
  border: 0px;
  background-color: rgb(153, 153, 153);
  cursor: pointer;
  z-index: 21;
  transition: 1s;
  transition-delay: 0s;
}
.nav-item:hover {
  color: rgb(85, 85, 85);
}
.nav-item-deco-a {
  color: rgb(85, 85, 85);
  position: relative;
  bottom: 0.04em;
}
.nav-item-deco-b {
  color: rgb(85, 85, 85);
  position: relative;
  left: 0%;
  z-index: 20;
  transition: 1s;
  bottom: 0.04em;
}

@media screen and (max-width: 52em) {
  .nav-item {
    font-size: 5em;
  }
}
@media screen and (max-width: 36em) {
  .nav-list {
    padding-left: 0;
    margin-left: 1em;
  }

  .nav-item {
    font-size: 3.5em;
    padding-left: 0;
    margin-left: 0;
  }
}
@media screen and (max-height: 600px) {
  .nav-item {
    font-size: 3.5em;
  }
}
