.co-content-wrap {
  display: flex;
  position: absolute;
  top: 12em;
  /* max-width: 90%; */
  width: 90%;
  margin-left: 5%;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 550px;
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-color: rgb(251, 176, 59) rgb(251, 176, 59, 0);
  scrollbar-width: thin;
  scroll-behavior: smooth;
  opacity: 0;
  transition: 2s;
  transition-delay: 2s;
  z-index: 3;
}
.co-content-wrap::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.co-content-wrap::-webkit-scrollbar-thumb {
  background: rgb(251, 176, 59);
}
.co-content-wrap::-webkit-scrollbar-track {
  background: rgb(251, 176, 59, 0);
}
.grid-content {
  padding-top: 1em;
  padding-bottom: 1em;
}

/* Form - fields */
.ui-input {
  position: relative;
  padding: 0;
  border: 0;
  width: 100%;
  margin-bottom: 0.5em;
}
.ui-input input {
  border: 0;
  background: none;
  padding: 2px 0 2px 0;
  font-size: 1.3em;
  outline: 0;
  width: 100%;
  /* tap-highlight-color: transparent;
  touch-callout: none; */
  color: rgb(85, 85, 85);
}

.ui-input textarea {
  border: 0;
  background: none;
  padding: 2px 0 2px 0;
  font-size: 1.5em;
  outline: 0;
  width: 100%;
  /* tap-highlight-color: transparent;
  touch-callout: none; */
}

.ui-input textarea + label,
.ui-input input + label {
  position: relative;
  display: block;
  padding: 8px 0 3px 0;
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 0.0875em;
  font-weight: 500;
  text-align: left;
}
.ui-input textarea + label::before,
.ui-input textarea + label::after,
.ui-input input + label::before,
.ui-input input + label::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
}
.ui-input textarea + label::before,
.ui-input input + label::before {
  background-color: rgb(104, 101, 101);
}
.ui-input textarea + label::after,
.ui-input input + label::after {
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: rgb(251, 176, 59);
  height: 2px;
}
.ui-input textarea + label span,
.ui-input input + label span {
  position: relative;
  color: rgb(85, 85, 85);
  transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ui-input textarea + label span::after,
.ui-input input + label span::after {
  /* content: attr(data-text);
  position: absolute;
  overflow: hidden;
  left: 0;
  transform: scaleX(1);
  white-space: nowrap;
  color: #fff;
  background-image: linear-gradient(
    to right,
    rgb(251, 176, 59) 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: 100% 50%;
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translateZ(0);
  transition: background-position 300ms cubic-bezier(0.215, 0.61, 0.355, 1); */
  visibility: hidden;
}
.ui-input textarea:focus + label::after,
.ui-input textarea.error + label::after,
.ui-input textarea:invalid + label::after,
.ui-input textarea.filled + label::after,
.ui-input input:focus + label::after,
.ui-input input.error + label::after,
.ui-input input:invalid + label::after,
.ui-input input.filled + label::after {
  transform: scaleX(1);
  transform-origin: left;
}
.ui-input textarea:focus + label span::after,
.ui-input textarea.error + label span::after,
.ui-input textarea:invalid + label span::after,
.ui-input textarea.filled + label span::after,
.ui-input input:focus + label span::after,
.ui-input input.error + label span::after,
.ui-input input:invalid + label span::after,
.ui-input input.filled + label span::after {
  background-image: linear-gradient(
    to right,
    white 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: 0% 50%;
}
.ui-input textarea.filled,
.ui-input input.filled {
  color: #ffffff;
}
.ui-input textarea.filled + label::after,
.ui-input input.filled + label::after {
  background-color: #ffffff;
}
.ui-input textarea.filled + label span::after,
.ui-input input.filled + label span::after {
  background-image: linear-gradient(
    to right,
    #ffffff 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: 0% 50%;
  visibility: hidden;
}
.ui-input textarea:focus,
.ui-input input:focus {
  color: rgb(85, 85, 85);
  background-color: rgba(179, 179, 179);
}
.ui-input textarea:focus + label::after,
.ui-input input:focus + label::after {
  background-color: rgb(251, 176, 59);
}
.ui-input textarea:focus + label span::after,
.ui-input input:focus + label span::after {
  background-image: linear-gradient(
    to right,
    rgb(251, 176, 59) 50%,
    rgba(255, 255, 255, 0) 0%
  );
  visibility: hidden;
  background-position: 0% 50%;
}
.ui-input input.error,
.ui-input input:invalid {
  color: #e66161;
}
.ui-input input.error + label::after,
.ui-input input:invalid + label::after {
  background-color: #e66161;
}
.ui-input input.error + label span::after,
.ui-input input:invalid + label span::after {
  background-image: linear-gradient(
    to right,
    #e66161 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: 0% 50%;
}
/* Form - Button */
.form-btn {
  background-color: transparent;
  border: 0;
  color: rgb(85, 85, 85);
  border-bottom: 1px solid rgb(85, 85, 85);
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 0.0875em;
  font-weight: 500;
  text-align: left;
  padding-left: 0;
  padding-right: 0.2em;
  padding-bottom: 0.2em;
  cursor: pointer;
  margin-top: 1em;
  margin-left: 1em;
}
.form-btn:hover,
.form-btn:focus {
  border-bottom: 2px solid rgb(251, 176, 59);
  /* background-color: rgb(179, 179, 179); */
}
.form-btn:focus {
  background-color: rgb(179, 179, 179);
}

/* Form - Error messages */
.contact-form-error {
  color: rgb(214, 213, 213);
  font-size: 0.9em;
  background-color: rgb(48, 48, 48);
  float: right;
  z-index: 8;
  position: absolute;
  right: 0%;
  bottom: 0;
  padding: 0.2em 0.5em 0.2em 0.5em;
  -webkit-box-shadow: 0px 0px 10px -3px #000000;
  box-shadow: 0px 0px 8px -3px #000000;
  border: 1px solid rgb(204, 204, 204);
}
#message-er,
#name-er,
#phone-er,
#email-er {
  display: none;
}
@media screen and (max-width: 90em) {
  .co-content-wrap {
    overflow-y: scroll;
    max-width: 610px;
    min-width: 610px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 52em) {
  .co-content-wrap {
    max-width: 90%;
    min-width: 90%;
    height: auto;
    bottom: 4em;
  }
}

@media screen and (max-width: 36em) {
  .co-content-wrap {
    bottom: 4em;
    height: auto;
  }
}

/* ---Height */
@media screen and (max-height: 37em) {
  .co-content-wrap {
    top: 4em;
    max-height: 80% !important;
  }
}

@media screen and (max-height: 47em) {
  .co-content-wrap {
    max-height: 60%;
  }
}
